import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamCard from "../components/team-card"

const Team = ({ data }) => (
  <Layout>
    <SEO title="Celantur Team" description="The people behind Celantur." />

    <section className="container pt-5 my-5">
      <div className="row justify-content-center">
        <div className="col-12 text-center mb-5">
          <h1 className="display-3 font-weight-bold">Team</h1>
        </div>
        <p className="font-size-lg text-muted text-center">
          Get to know the Celantur team.
          <br /> We're engineers and people passionate about technology, in pursuit of advancing humanity with our
          products and inventions.
        </p>
        <Link to="/contact/" className="btn btn-success event-contact">
          Join us
        </Link>
      </div>
    </section>

    <section className="container pt-5 my-5 text-center">
      <div className="row">
        <TeamCard
          name="Alexander Petkov"
          color="#4285f4"
          image={data.imageAlex.childImageSharp.fixed}
          text="I founded Celantur because of a sense of adventure, challenge and huge learning opportunity.
					I'm really proud of our team of very bright minds. Together we're building world-class solutions for industry-leading companies.
					This still leaves me in awe when I realize how far a simple idea can go with commitment and passion.
					Besides of work I love to spend time with family and friends, cook, do sports and read a lot to understand the world a bit better each day."
          role="CEO"
          link="https://www.linkedin.com/in/alexander-petkov-at/"
        />

        <TeamCard
          name="Boyang Xia"
          color="#4285f4"
          image={data.imageBoyang.childImageSharp.fixed}
          text="I started my career as a chemist working in the quality assurance of a global pharmaceutical company. Realizing that 80% of my job could be automated by computers, I transitioned to machine learning and software development with the goal to free people from repetitive desktop tasks so that they can focus on purposeful work.
Thus together with Alex, I founded Celantur with our solution to automate the blurring of personal data, enabling companies to focus on their core business.
In my leisure time, I engage in Effective Altruism and coordinate a local group."
          role="CTO"
          link="https://www.linkedin.com/in/b0x1/"
        />

        <TeamCard
          name="Georgii Krikun"
          color="#4285f4"
          image={data.imageGeorgii.childImageSharp.fixed}
          text="During my years as Ph.D., I realized that the activity that accompanies any task in theoretical physics, namely creating computer algorithms that can achieve the goal most efficiently, slowly became more interesting to me than physics itself. Therefore, I moved into the field of performance computing, where I could find the best application for my skills. I joined Celantur because the task here is a direct challenge to my skills and the company works in the data security field, which I take very seriously. In my home country, Russia, there were a large number of personal data leaks from big companies. It lead to a substantial increase in online crimes, particularly frauds, in recent years."
          role="Performance Engineer"
          link="https://www.linkedin.com/in/georgii-krikun/"
        />

        <TeamCard
          name="Dominik Heindl"
          color="#4285f4"
          image={data.imageDominik.childImageSharp.fixed}
          text="With the ever-growing demand for new data in our digital world, I think it is important to preserve the privacy of individuals and prevent the accumulation of personal data in unwanted places. Celantur is helping mitigate the damage of uncontrolled data collection, at least in images and videos. Their approach to anonymizing personal data stood out to me and sparked my interest in the company. I wanted to be part of this amazing idea and help with my expertise. With my background in Machine Learning, I try to improve the automatic detection of personal data and help individuals to preserve their privacy. Besides work, I like to spend my time with friends and family, playing video games and football or going for a hike."
          role="Machine Learning Engineer"
          link="https://www.linkedin.com/in/dominik-heindl-2b76881b8/"
        />

        <TeamCard
          name="Ivan Ponomarev"
          color="#4285f4"
          image={data.imageIvan.childImageSharp.fixed}
          text="During my work experience, I faced a lot of enterprise projects that had to be carefully maintained and improved.
          I adore sophisticated systems and software, especially if those are focused on protecting people's privacy.
          This is the major reason why I joined the Celantur team.
          I enjoy being part of the team to hone and improve the system for our customers and their privacy.
          During the Information Age it's critical to maintain and develop ethics in all fields of our life."
          role="Software & System Architect"
          link="https://www.linkedin.com/in/ponomarevivan/"
        />

        <TeamCard
          name="Kristian Todt"
          color="#4285f4"
          image={data.imageKristian.childImageSharp.fixed}
          text="As a student in the midst of my Software Engineering Bachelor's, when I came across the opportunity to work with Celantur I was intrigued greatly. Though my Bachelor focuses on more traditional Software Engineering, I've always had a voracious appetite for knowledge - eager to learn whatever I could about a certain technology. I found Celantur's mission very relevant given the growing interest in online privacy and as an intern I want to absorb as much as I can while still taking the initiative and using my judgement where possible. In my free time I am very passionate about aviation, programming my own games and hiking."
          role="Image Annotator"
          link="https://www.linkedin.com/in/kristian-todt/"
        />
      </div>
    </section>

    <section className="pt-6 pt-md-8">
      <div className="container pb-6 pb-md-8">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            <h3 className="font-weight-bold mb-1">Interested in joining our team?</h3>
            <p className="font-size-lg text-muted mb-5 mb-md-0">Hit us up and we'll get in touch with you.</p>
          </div>
          <div className="col-12 col-md-auto">
            <Link to="/contact/" className="btn btn-primary event-contact">
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Team

export const query = graphql`
  fragment teamImage on File {
    childImageSharp {
      fixed(width: 196, height: 196) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  query {
    imageAlex: file(relativePath: { eq: "team/alex.jpg" }) {
      ...teamImage
    }
    imageBoyang: file(relativePath: { eq: "team/boyang.jpg" }) {
      ...teamImage
    }
    imageGeorgii: file(relativePath: { eq: "team/georgii.png" }) {
      ...teamImage
    }
    imageDominik: file(relativePath: { eq: "team/dominik.png" }) {
      ...teamImage
    }
    imageIvan: file(relativePath: { eq: "team/ivan.jpg" }) {
      ...teamImage
    }
    imageKristian: file(relativePath: { eq: "team/kristian.jpg" }) {
      ...teamImage
    }
  }
`
